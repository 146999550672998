<template>
  <el-dialog
    title="授权"
    :visible.sync="powerVisible"
    width="700px"
    :close-on-click-modal="false"
    :before-close="closeDialog"
    v-ismove>
    <el-form :model="powerForm" :rules="formRule" ref="powerForm" size="small" label-width="60px" inline>
      <el-form-item prop="ud" label="部门">
        <el-select v-model="powerForm.ud" placeholder="请选择部门" @change="departmentChange" clearable>
          <el-option v-for="item in departmentList" :key="item.udId" :label="item.udName" :value="item.udId + '-' + item.udName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="user" label="人员">
          <el-select v-model="powerForm.user" placeholder="请选择人员" :disabled="!powerForm.ud">
            <el-option v-for="item in userList" :key="item.userId" :label="item.name" :value="item.userId + '-' + item.name"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="commitAdd">添加授权</el-button>
      </el-form-item>
    </el-form>
    <!-- 授权的表格数据 -->
    <el-table
      v-loading="isListLoading"
      :data="powerTableList"
      border
      style="width: 100%"
      size="small"
      :header-cell-style="{textAlign:'center', background: '#41A1FF', color: '#ffffff'}">
      <el-table-column prop="udName" label="部门" align="center"> </el-table-column>
      <el-table-column prop="userName" label="人员" align="center"> </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="deleteClick(scope.row)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeDialog">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  getDepartmentUser,
  separationPowers,
  separationPowersPage,
  deleteSeparationPowers
} from '@/api/post'
import { requiredC } from '@/utils/validate'

export default {
  props: {
    powerVisible: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      isListLoading: false,
      powerTableList: [],
      departmentList: [],
      userList: [],
      powerForm: {
        ud: '',
        user: ''
      },
      formRule: {
        ud: [requiredC()],
        user: [requiredC()]
      }
    }
  },
  created() {
    this.getDepartmentList()
    this.getPowerList()
  },
  methods: {
    getDepartmentList() {
      getDepartmentUser()
        .then((res) => {
          if (res.code === '000000' && res.data) {
            this.departmentList = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 获取已授权的列表数据
    getPowerList() {
      this.isListLoading = true
      separationPowersPage().then(response => {
        this.isListLoading = false
        if (response.code === '000000' && response.data.list) {
          this.powerTableList = response.data.list
        } else {
          this.powerTableList = []
        }
      }).catch(err => {
        this.powerTableList = []
        this.isListLoading = false
        console.log(err)
      })
    },
    departmentChange(value) {
      this.powerForm.user = ''
      this.userList = []
      if (value) {
        const obj = this.departmentList.find(
          (val) => val.udId === Number(this.powerForm.ud.split('-')[0])
        )
        this.userList = obj.userInfoVoList
      }
    },
    commitAdd() {
      this.$refs.powerForm.validate((valid) => {
        if (valid) {
          const params = {
            udId: Number(this.powerForm.ud.split('-')[0]),
            udName: this.powerForm.ud.split('-')[1],
            userId: Number(this.powerForm.user.split('-')[0]),
            userName: this.powerForm.user.split('-')[1]
          }
          separationPowers(params)
            .then((res) => {
              if (res.code === '000000') {
                this.$message({
                  showClose: true,
                  type: 'success',
                  message: '成功!'
                })
              }
              this.getPowerList()
            })
            .catch()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleteClick(row) {
      this.$confirm('是否删除该负责人授权：' + row.userName, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteSeparationPowers(row.spId)
            .then((res) => {
              if (res.code === '000000') {
                this.$message({
                  showClose: true,
                  type: 'success',
                  message: '删除成功!'
                })
              }
              this.getPowerList()
            })
            .catch(() => {})
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    closeDialog() {
      this.$refs.powerForm.resetFields()
      this.$emit('closeDialog')
    }
  }
}
</script>

<style>

</style>
